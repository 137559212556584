// your-dialog.jsx
import React from "react";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { Cross1Icon } from "@radix-ui/react-icons";
import clsx from "clsx";
import { useStyleOverwritesContainer } from "@/providers/StyleOverwrites";

export const Dialog = DialogPrimitive.Root;
export const DialogTrigger = DialogPrimitive.Trigger;

export const DialogContent = React.forwardRef<
    HTMLDivElement,
    React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
>(({ children, ...props }, forwardedRef) => {
    const { containerRef } = useStyleOverwritesContainer();
    return (
        <DialogPrimitive.Portal container={containerRef.current}>
            <DialogPrimitive.Overlay className="fixed inset-0 bg-black/80 backdrop-blur-sm data-[state=open]:animate-overlayShow" />
            <DialogPrimitive.Content 
                {...props} 
                ref={forwardedRef} 
                className="fixed left-1/2 top-1/2 max-h-[85vh] w-[90vw] max-w-[450px] -translate-x-1/2 -translate-y-1/2 rounded-md bg-[var(--shop-panelBackgroundColor)] text-[var(--shop-textColor)] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none data-[state=open]:animate-contentShow"
            >
                <div className="relative h-full max-h-[85vh] overflow-y-auto">
                    <div className="px-4 md:px-8 py-4">
                        {children}
                    </div>
                    <DialogPrimitive.Close aria-label="Close" className="absolute top-4 right-4">
                        <Cross1Icon className="w-6 h-6 text-[var(--shop-textColor)] hover:opacity-70 hover:scale-110 transition-all duration-300" />
                    </DialogPrimitive.Close>
                </div>
            </DialogPrimitive.Content>
        </DialogPrimitive.Portal>
    );
});

export const DialogTitle = React.forwardRef<
  HTMLHeadingElement,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, forwardedRef) => (
  <DialogPrimitive.Title
    ref={forwardedRef}
    className={clsx('text-lg font-semibold mb-2 text-[var(--shop-textColor)]', className)}
    {...props}
  />
));

export const DialogDescription = React.forwardRef<
  HTMLParagraphElement,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, forwardedRef) => (
  <DialogPrimitive.Description
    ref={forwardedRef}
    className={clsx('text-sm text-[var(--shop-textColor)] opacity-70', className)}
    {...props}
  />
));

export const DialogClose = DialogPrimitive.Close;

export function CartTimeoutDialog({ isOpen, onClose }: { isOpen: boolean, onClose: () => void }) {
    return (
        <Dialog open={isOpen}>
            <DialogContent>
                <DialogTitle>Cart Timeout</DialogTitle>
                <DialogDescription className="mt-2">
                    Your cart has timed out. You will be redirected to the marketplace.
                </DialogDescription>

                <div className="mt-4">
                    <button
                        type="button"
                        className="bg-[var(--shop-ctaButtonColor)] text-[var(--shop-ctaButtonTextColor)] p-2 rounded-md text-center block w-full hover:opacity-90 transition-all duration-300 hover:shadow-md"
                        onClick={onClose}
                    >
                        OK
                    </button>
                </div>
            </DialogContent>
        </Dialog>
    );
}