import React from 'react';

interface NotClaimedIconProps {
  className?: string;
}

export const NotClaimedIcon: React.FC<NotClaimedIconProps> = ({ className = '' }) => {
  return (
    <svg 
      width="35" 
      height="35" 
      viewBox="0 0 35 35" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M11.3203 14.9297C13.0339 16.6432 15.0937 17.5 17.5 17.5C19.9062 17.5 21.9661 16.6432 23.6797 14.9297C25.3932 13.2161 26.25 11.1562 26.25 8.75C26.25 6.34375 25.3932 4.28385 23.6797 2.57031C21.9661 0.856771 19.9062 0 17.5 0C15.0937 0 13.0339 0.856771 11.3203 2.57031C9.60677 4.28385 8.75 6.34375 8.75 8.75C8.75 11.1562 9.60677 13.2161 11.3203 14.9297ZM0 28.875V35H35V28.875C35.0015 27.6369 34.6828 26.4979 34.0441 25.4581C33.4053 24.4183 32.5573 23.625 31.5 23.0781C29.2396 21.9508 26.9427 21.1035 24.6094 20.5362C22.276 19.969 19.9062 19.686 17.5 19.6875C15.0937 19.689 12.724 19.9719 10.3906 20.5362C8.05729 21.1006 5.76042 21.9479 3.5 23.0781C2.44417 23.6265 1.59688 24.4198 0.958125 25.4581C0.319375 26.4965 0 27.6354 0 28.875ZM15.0926 25.1861C15.0811 25.1535 15.0759 25.1187 15.0773 25.0838C15.1716 23.2525 16.5497 22.6154 17.8077 22.6154C19.1947 22.6154 20.4617 23.4036 20.4617 25.0352C20.4617 26.2003 19.8312 26.7553 19.2265 27.2553C18.4948 27.8589 18.2237 28.0846 18.2237 28.8599V28.9732C18.2237 29.0448 18.1975 29.1135 18.151 29.1641C18.1044 29.2148 18.0413 29.2432 17.9755 29.2432H17.1702C17.1051 29.2432 17.0426 29.2154 16.9961 29.1657C16.9497 29.116 16.9231 29.0484 16.922 28.9776L16.9191 28.7432C16.8813 27.7423 17.4105 27.1257 18.0787 26.5977C18.6655 26.1182 19.0369 25.8029 19.0369 25.1173C19.0369 24.2264 18.4133 23.8561 17.7322 23.8561C16.9359 23.8561 16.4891 24.3722 16.3998 25.0806C16.3819 25.2285 16.2727 25.3505 16.1357 25.3505H15.3165C15.2844 25.3511 15.2526 25.3446 15.2229 25.3313C15.1932 25.3181 15.1663 25.2983 15.1439 25.2734C15.1215 25.2484 15.104 25.2187 15.0926 25.1861ZM17.626 32.3077C17.0451 32.3077 16.6232 31.8822 16.6232 31.3067C16.6232 30.7106 17.0451 30.2917 17.625 30.2917C18.2297 30.2917 18.6467 30.7106 18.6467 31.3067C18.6467 31.8822 18.2306 32.3077 17.626 32.3077Z"/>
    </svg>
  );
}; 