import React from 'react';

interface QrIconProps {
  className?: string;
}

export const QrIcon: React.FC<QrIconProps> = ({ className = '' }) => {
  return (
    <svg 
      width="35" 
      height="35" 
      viewBox="0 0 35 35" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
    >
      <path d="M5.83333 5.83333H14.5833V14.5833H5.83333V5.83333ZM29.1667 5.83333V14.5833H20.4167V5.83333H29.1667ZM20.4167 21.875H23.3333V18.9583H20.4167V16.0417H23.3333V18.9583H26.25V16.0417H29.1667V18.9583H26.25V21.875H29.1667V26.25H26.25V29.1667H23.3333V26.25H18.9583V29.1667H16.0417V23.3333H20.4167V21.875ZM23.3333 21.875V26.25H26.25V21.875H23.3333ZM5.83333 29.1667V20.4167H14.5833V29.1667H5.83333ZM8.75 8.75V11.6667H11.6667V8.75H8.75ZM23.3333 8.75V11.6667H26.25V8.75H23.3333ZM8.75 23.3333V26.25H11.6667V23.3333H8.75ZM5.83333 16.0417H8.75V18.9583H5.83333V16.0417ZM13.125 16.0417H18.9583V21.875H16.0417V18.9583H13.125V16.0417ZM16.0417 8.75H18.9583V14.5833H16.0417V8.75ZM2.91667 2.91667V8.75H0V2.91667C0 2.14312 0.307291 1.40125 0.854272 0.854272C1.40125 0.307291 2.14312 0 2.91667 0L8.75 0V2.91667H2.91667ZM32.0833 0C32.8569 0 33.5987 0.307291 34.1457 0.854272C34.6927 1.40125 35 2.14312 35 2.91667V8.75H32.0833V2.91667H26.25V0H32.0833ZM2.91667 26.25V32.0833H8.75V35H2.91667C2.14312 35 1.40125 34.6927 0.854272 34.1457C0.307291 33.5987 0 32.8569 0 32.0833V26.25H2.91667ZM32.0833 32.0833V26.25H35V32.0833C35 32.8569 34.6927 33.5987 34.1457 34.1457C33.5987 34.6927 32.8569 35 32.0833 35H26.25V32.0833H32.0833Z"/>
    </svg>
  );
}; 