import { TicketShopContainer } from '@/TicketshopContainer'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/$slug/$event')({
  component: Event,
})

function Event() {
    const { slug, event } = Route.useParams()
    return <TicketShopContainer shopSlug={slug} eventSlug={event} usePageBackground={true} />;
}