import { createRouter, RouterProvider } from "@tanstack/react-router";
import { routeTree } from "./routeTree.gen";
import { initializeSentry } from "./lib/sentry";

const router = createRouter({
    routeTree,
    defaultPreload: 'intent',
    context: {
      marketplace: undefined!,
    },
  })
  
//   ReactGA.initialize(TRACKING_ID);
  
  router.subscribe('onResolved', () => {
    // ReactGA.send({ hitType: "pageview", page: router.state.location.pathname });
  });

  // Register things for typesafety
  declare module '@tanstack/react-router' {
    interface Register {
      router: typeof router
    }
  }

export function RouterRoot() {
    // Initialize Sentry with router
    initializeSentry(router);

    return (
        <RouterProvider router={router} />
    );
  }