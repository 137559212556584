import React from 'react';
import { useCart } from '@/providers/CartProvider';
import { CartStep } from '@/providers/CartProvider';
import { Panel } from '@/components/shared/Panel';
import { MainButton} from '@/components/shared/MainButton';
import { RiArrowLeftSLine, RiArrowRightSLine } from '@remixicon/react';
import { DiscountCodes } from './DiscountCodes';
import { OrganisationLegalInformation } from '../OrganisationLegalInformation';
import { useLanguage } from '@/providers/LanguageProvider';
import * as m from "@/paraglide/messages.js"
import { useShop } from '@/providers/ShopProvider';

export const TotalAndCtaComponent: React.FC = () => {
    const { costOverview, cartStep, setStep, formIsValid, submitFormAnswers, loading } = useCart();
    const { currentLanguage: lang } = useLanguage();

    const { shop } = useShop();
    const { organisationLegalInformation } = shop;
    
    return (
        <Panel>
            {cartStep === CartStep.Tickets && (
                <DiscountCodes />
            )}
            <div>
                <div className='text-xl font-bold'>{m.total()}</div>
                <div className='text-lg font-normal leading-7'>{costOverview().total.display(lang)}</div>
            </div>
            <div className='pt-2'>
                {cartStep === CartStep.Tickets && (
                <MainButton disabled={costOverview().products.length === 0} onClick={() => {
                    if (costOverview().products.length > 0) {
                        setStep(CartStep.Order);
                    }
                }}>
                    <div className='text-xl font-bold leading-7'>{m.next()}</div>
                    <RiArrowRightSLine className="h-8 w-8 shrink-0 group-hover:translate-x-2 group-hover:scale-110 transition-transform duration-200"/>
                </MainButton>)}

                {cartStep === CartStep.Order && (
                    <div className='flex gap-2'>
                        <div className='border-2 border-black rounded-md p-2 hover:cursor-pointer hover:opacity-80 hover:bg-gray-100' onClick={async () => {
                            await submitFormAnswers();
                            setStep(CartStep.Tickets);
                        }}>
                            <RiArrowLeftSLine className="h-10 w-10 shrink-0 group-hover:translate-x-2 group-hover:scale-110 transition-transform duration-200"/>
                        </div>
                        <MainButton disabled={!formIsValid} 
                            loading={loading}
                            className='flex-grow'
                            onClick={async () => {
                                const result = await submitFormAnswers();
                                // Only proceed if there are no server-side errors
                                if (result && Object.keys(result.errors).length === 0) {
                                    setStep(CartStep.Checkout);
                                }
                            }}>
                            <div className='text-lg font-bold leading-7'>{m.next()}</div>
                            <RiArrowRightSLine className="h-10 w-10 shrink-0 group-hover:translate-x-2 group-hover:scale-110 transition-transform duration-200"/>
                        </MainButton>
                    </div>
                )}
            </div>
            {organisationLegalInformation.showInFooter !== false && (
                <OrganisationLegalInformation/>
            )}
        </Panel>
    );
};