import React from 'react';

interface TicketClaimedIconProps {
  className?: string;
}

export const TicketClaimedIcon: React.FC<TicketClaimedIconProps> = ({ className = '' }) => {
  return (
    <svg 
      width="33" 
      height="35" 
      viewBox="0 0 33 35" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M21.3612 16.0254C19.9223 16.9868 18.2306 17.5 16.5 17.5C14.1794 17.5 11.9538 16.5781 10.3128 14.9372C8.67187 13.2962 7.75 11.0706 7.75 8.75C7.75 7.01941 8.26318 5.32769 9.22464 3.88876C10.1861 2.44983 11.5527 1.32832 13.1515 0.666058C14.7504 0.00379123 16.5097 -0.169488 18.207 0.168133C19.9044 0.505753 21.4635 1.33911 22.6872 2.56282C23.9109 3.78653 24.7442 5.34563 25.0819 7.04296C25.4195 8.74029 25.2462 10.4996 24.5839 12.0985C23.9217 13.6973 22.8002 15.0639 21.3612 16.0254ZM0.25 30C0.25 23.35 11.0766 20 16.5 20C21.9234 20 32.75 23.35 32.75 30V35H0.25V30ZM20.4722 24.8469C20.4722 24.6518 20.4046 24.4647 20.2842 24.3268C20.2246 24.2584 20.1538 24.2042 20.0759 24.1672C19.998 24.1302 19.9145 24.1111 19.8301 24.1111C19.7458 24.1111 19.6623 24.1302 19.5843 24.1672C19.5064 24.2042 19.4356 24.2584 19.376 24.3268L14.9876 29.3559L13.0205 27.1018C12.9613 27.0315 12.8904 26.9754 12.8121 26.9369C12.7338 26.8983 12.6495 26.878 12.5643 26.8772C12.479 26.8763 12.3945 26.8949 12.3156 26.9319C12.2367 26.9689 12.165 27.0236 12.1047 27.0926C12.0444 27.1617 11.9968 27.2438 11.9645 27.3343C11.9322 27.4247 11.9159 27.5215 11.9167 27.6192C11.9174 27.7169 11.9351 27.8135 11.9688 27.9032C12.0024 27.993 12.0513 28.0741 12.1127 28.142L14.5035 30.8812C14.5671 30.9541 14.6426 31.0119 14.7257 31.0514C14.8088 31.0908 14.8979 31.1111 14.9878 31.1111C15.0778 31.1111 15.1668 31.0908 15.2499 31.0514C15.333 31.0119 15.4085 30.9541 15.4721 30.8812L20.2842 25.367C20.4046 25.229 20.4722 25.042 20.4722 24.8469Z"/>
    </svg>
  );
}; 