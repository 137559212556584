import { Event, FormComponent, MoneyValues, OrderedList, PaymentMethod, ServiceFee, ShopStyle } from "./TicketApi";

export interface Order {
    orderId: string;
    shopStyle: ShopStyle;
    tickets: Ticket[];
    event: Event;
    formAnswers: Record<string, object[] | string[]>;
    contactDetails: {
        firstName: string;
        lastName: string;
        email: string;
    };
    marketplaceUrl: string|null;
}

export interface SharedTicket {
    orderId: string;
    shopStyle: ShopStyle;
    ticket: Ticket;
    event: Event;
}

export interface PendingPersonalisationTicket {
    shopStyle: ShopStyle;
    event: Event;
    paymentMethods: PaymentMethod[];
    price: MoneyValues;
    paidShareFee: ServiceFee|null;
    service_fee: MoneyValues;
    share_code: string;
    form: OrderedList<FormComponent>;
    should_pay: boolean;
    shared_to: string|null;
    shared_by: string;
    productName: string;
    payment_pending: boolean;
    paid: boolean;
    orderId: string|null;
}

interface BaseTicket {
    ticketId: string;
    productId: string;
    productName: string;
    price: MoneyValues;
    serviceFee: MoneyValues;
    numberOfProductType: number;
    totalOfProductType: number;
    selfClaimed?: boolean;
    ticketMustBePersonalized: boolean;
}

export interface ResaleProperties {
    originalPriceInclFee: MoneyValues;
    maxResellPrice: MoneyValues;
    maxResellPercentage: number;
    profitSplitPercentageForOrganizer: number;
    resaleFeeSeller: ServiceFee;
    resaleFeeBuyer: ServiceFee;
    onMarketplace: boolean;
    listingPriceExFee?: MoneyValues;
}

type WithResale<T> = T & {
    canResell: boolean;
} & (
    | { canResell: true; } & ResaleProperties
    | { canResell: false; }
);

// export interface AvailableTicket extends BaseTicket, sharableTicketAttributes {
//     ticketStatus: TicketStatus.available;
//     wallet_download_url: string|null;
//     pdf_download_url: string|null;
//     barcode: string;
// }

export interface sharableTicketAttributes {
    claimForm: OrderedList<FormComponent>;
    allowPaidShare: boolean;
    isSelfClaimable: boolean;
    paidShareFee: ServiceFee|null;
}

// Note: extending BaseTicket here in the inner type
export type ClaimableTicket = WithResale<BaseTicket & {
    ticketStatus: TicketStatus.claimable;
} & sharableTicketAttributes>;


export type AvailableTicket = WithResale<BaseTicket & {
    ticketStatus: TicketStatus.available;
    wallet_download_url: string|null;
    pdf_download_url: string|null;
    barcode: string;
    sealed: boolean;
    availableFrom: string|null;
} & sharableTicketAttributes>;

export type SharedTicketStatus = BaseTicket & {
    ticketStatus: TicketStatus.shared;
    shareCode: string;
    shareName?: string;
    inviteeShouldPay: boolean;
};

export type ForSaleTicket = BaseTicket & {
    ticketStatus: TicketStatus.forSale;
    listingPriceExFee: MoneyValues;
    listingId: string;
    sellerReceivable: MoneyValues;
    organizationReceivableAmount: MoneyValues;
    resaleFeeAmountSeller: MoneyValues;
    sellerFee: ServiceFee;
    maxResellPercentage: number;
    profitSplitPercentageForOrganizer: number;
    canResell: boolean;
    onMarketplace: boolean;
};

export interface ClaimedTicket extends BaseTicket {
    ticketStatus: TicketStatus.claimed;
    claimedAt: string;
    claimedBy: string;
}

export interface SoldTicket extends BaseTicket {
    ticketStatus: TicketStatus.sold;
}

export type Ticket = AvailableTicket | ClaimableTicket | ClaimedTicket | SharedTicketStatus | ForSaleTicket | SoldTicket;

export enum TicketStatus {
    available = "available",
    shared = "shared",
    claimed = "claimed",
    claimable = "claimable",
    forSale = "forSale",
    sold = "sold",
}

export class OrderApi {
    constructor(
        private readonly baseUrl: string,
    ) {
    }
    public async getOrder(orderId: string): Promise<Order> {
        const res = await fetch(`${this.baseUrl}/orders/${orderId}`, {cache: "no-cache"});
        if(res.status === 404) {
            throw new Error("Order not found");
        }
        return await res.json();
    }

    public async getSharedTicket(ticketId: string): Promise<SharedTicket> {
        const res = await fetch(`${this.baseUrl}/tickets/shared/${ticketId}`, {cache: "no-cache"});
        return await res.json();
    }

    public async getPendingPersonalisationTicketByCode(shareCode: string, orderId?: string): Promise<PendingPersonalisationTicket> {
        const res = await fetch(`${this.baseUrl}/pending-personalisation/${shareCode}?` + (orderId ? `order_id=${orderId}` : ""), {cache: "no-cache", headers: {
            "Accept": "application/json",
        }});

        if(res.status === 404) {
            throw new Error("Ticket not found");
        }
        return await res.json();
    }

    public async selfClaimTicket(orderId: string, ticketId: string, answers: object) {
        // v1/orders/{orderId}/tickets/{ticketId}/self-claim
        const res = await fetch(`${this.baseUrl}/orders/${orderId}/tickets/${ticketId}/self-claim`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                answers: answers
            }),
        });
        return await res.json();
    }

    public async claimTicket(shareCode: string, answers: object, paymentMethod: string|null = null): Promise<{type: "no_payment", new_order_id: string} | {type: "redirect", url: string}> {
        const res = await fetch(`${this.baseUrl}/pending-personalisation/${shareCode}/claim`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                answers: answers,
                paymentMethodId: paymentMethod,
                redirectAfterPaymentUrl: window.location.href,
            }),
        });
        return await res.json();
    }


    public async shareTicket(orderId: string, ticketId: string, name?: string, inviteeShouldPay: boolean = false): Promise<string> {
        const res = await fetch(`${this.baseUrl}/orders/${orderId}/tickets/${ticketId}/share`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name: name,
                inviteeShouldPay: inviteeShouldPay,
            }),
        });
        const response = await res.json();
        return response.share_code;
    }

    public async listTicket(orderId: string, ticketId: string, price: MoneyValues): Promise<string> {
        const res = await fetch(`${this.baseUrl}/orders/${orderId}/tickets/${ticketId}/list`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({
                listing_price: price.amount,
            }),
        });
        const response = await res.json();
        return response.listingId;
    }

    public async unlistTicket(orderId: string, ticketId: string): Promise<true|string> {
        const res = await fetch(`${this.baseUrl}/orders/${orderId}/tickets/${ticketId}/unlist`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({
            }),
        });
        const response = await res.json();
        
        if(response.success) {
            return true;
        }

        return response.reason;
    }

    public async cancelShareTicket(orderId: string, ticketId: string): Promise<{success: boolean, message?: string}> {
        const res = await fetch(`${this.baseUrl}/orders/${orderId}/tickets/${ticketId}/cancel-share`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
        });
        const response = await res.json();
        if (res.status === 400) {
            return { success: false, message: response.message };
        }
        if (res.ok) {
            return { success: true };
        }
        return { success: false, message: response.message || "Something went wrong" };
    }
}
