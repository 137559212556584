import React from 'react';
import { ShopProvider } from './providers/ShopProvider';
import { CartProvider } from './providers/CartProvider';

interface TicketShopProps {
  shopSlug: string;
  eventSlug: string;
  isWidget?: boolean;
  usePageBackground?: boolean;
}
import { EventContainer } from './EventContainer';
import { LanguageProvider } from './providers/LanguageProvider';
import { TrackingProviderComponent } from './tracking/context';

export const TicketShopContainer: React.FC<TicketShopProps> = ({ shopSlug, eventSlug, isWidget = false, usePageBackground = false }) => {
  return (
    <TrackingProviderComponent initialConfigs={[
      {type: "GOOGLE_ANALYTICS", id: "G-27QZ6PZXLM"},
    ]}>
      <LanguageProvider>
        <ShopProvider shopSlug={shopSlug} baseUrl={import.meta.env.VITE_API_URL} isWidget={isWidget} usePageBackground={usePageBackground}>
            <CartProvider>
              <EventContainer eventSlug={eventSlug}/>
            </CartProvider>
        </ShopProvider>
      </LanguageProvider>
    </TrackingProviderComponent>
  );
};