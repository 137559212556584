import { Field, Label, Radio, RadioGroup } from '@headlessui/react';
import { shopVar } from '@/lib/shopStyles';
import { useLanguage } from '@/providers/LanguageProvider';
import * as m from "@/paraglide/messages.js"
import { ServiceFee } from '@/lib/TicketApi';
import { Money } from '@/lib/money';

type PaymentMethod = {
    id: string;
    name: string;
    image: string;
    fee: ServiceFee;
}

interface SelectPaymentMethodProps {
    paymentMethods: PaymentMethod[];
    selectedMethod: string|null;
    onMethodChange: (method: string) => void;
    className?: string;
    additionalFee?: Money;
}

export function SelectPaymentMethod({ paymentMethods, selectedMethod, onMethodChange, className, additionalFee }: SelectPaymentMethodProps) {
    const { currentLanguage: lang } = useLanguage();

    return (
        <RadioGroup value={selectedMethod} onChange={onMethodChange} aria-label="Payment method" className={`flex flex-col gap-4 ${className}`}>
            {paymentMethods.map((method) => {
                const methodFee = method.fee.amountPerUnit ? Money.fromInteger(method.fee.amountPerUnit.amount, method.fee.amountPerUnit.currency) : Money.zero(additionalFee?.currency || 'EUR');
                const totalFee = additionalFee && methodFee.currency === additionalFee.currency ? methodFee.add(additionalFee) : methodFee;
                
                return (
                    <Field key={method.id} className="flex items-center gap-6 w-full hover:cursor-pointer">
                        <Radio
                            value={method.id}
                            className="group flex size-6 items-center justify-center rounded-full border"
                            style={{
                                borderColor: shopVar('--shop-textColor'),
                                ...(selectedMethod === method.id ? {
                                    backgroundColor: shopVar('--shop-textColor'),
                                } : {
                                    backgroundColor: shopVar('--shop-panelBackgroundColor'),
                                })
                            }}
                        >
                            <span className="invisible size-2.5 rounded-full group-data-[checked]:visible" style={{backgroundColor: shopVar('--shop-panelBackgroundColor')}} />    
                        </Radio>
                        <Label className='hover:cursor-pointer flex items-center gap-4'>
                            <img src={method.image} alt={method.name} className="w-10"/>
                            <div className="flex flex-col justify-between" style={{color: shopVar('--shop-textColor')}}>
                                <span className='font-semibold'>{method.name}</span>
                                <div className="text-sm tracking-tight">{totalFee.isZero() ? m.payment_method_free() : totalFee.display(lang)}</div>
                            </div>
                        </Label>
                    </Field>
                );
            })}
        </RadioGroup>
    );
} 