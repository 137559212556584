import { useEffect, useState } from 'react';
import { useNavigate } from '@tanstack/react-router';
import { CartTimeoutDialog } from '@/components/shared/Dialog';

interface CartTimeoutWarningProps {
    timesOutAtUtc: string;
    slug: string;
}

export function CartTimeoutWarning({ timesOutAtUtc, slug }: CartTimeoutWarningProps) {
    const [timeLeft, setTimeLeft] = useState<{ minutes: number; seconds: number }>({ minutes: 0, seconds: 0 });
    const [showTimeoutDialog, setShowTimeoutDialog] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const calculateTimeLeft = () => {
            // Get current time in UTC
            const now = new Date();
            const utcNow = Date.UTC(
                now.getUTCFullYear(),
                now.getUTCMonth(),
                now.getUTCDate(),
                now.getUTCHours(),
                now.getUTCMinutes(),
                now.getUTCSeconds(),
                now.getUTCMilliseconds()
            );

            // Parse the UTC timeout string
            const [datePart, timePart] = timesOutAtUtc.split('T');
            const [year, month, day] = datePart.split('-').map(Number);
            const [hours, minutes, secondsAndMillis] = timePart.split(':');
            const [seconds, millisStr] = secondsAndMillis.split('.');
            const millis = millisStr ? Number(millisStr.slice(0, 3)) : 0;

            const timeoutDate = Date.UTC(
                year,
                month - 1, // JavaScript months are 0-based
                day,
                Number(hours),
                Number(minutes),
                Number(seconds),
                millis
            );

            const difference = timeoutDate - utcNow;
            
            if (difference <= 0) {
                return { minutes: 0, seconds: 0 };
            }

            const minutesLeft = Math.floor(difference / 1000 / 60);
            const secondsLeft = Math.floor((difference / 1000) % 60);

            return { minutes: minutesLeft, seconds: secondsLeft };
        };

        // Initial calculation
        const initialTimeLeft = calculateTimeLeft();
        setTimeLeft(initialTimeLeft);

        if (initialTimeLeft.minutes === 0 && initialTimeLeft.seconds === 0) {
            handleTimeout();
        }

        // Update every second
        const timer = setInterval(() => {
            const newTimeLeft = calculateTimeLeft();
            setTimeLeft(newTimeLeft);
            
            if (newTimeLeft.minutes === 0 && newTimeLeft.seconds === 0) {
                handleTimeout();
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [timesOutAtUtc]);

    const handleTimeout = () => {
        localStorage.removeItem('marketplace_cart_id');
        setShowTimeoutDialog(true);
    };

    const handleTimeoutDialogClose = () => {
        setShowTimeoutDialog(false);
        navigate({ 
            to: '/marketplace/$slug', 
            params: { slug },
            search: { listing: undefined }
        });
        window.location.reload();
    };

    if (!timesOutAtUtc) {
        return null;
    }

    const formatTime = () => {
        if (timeLeft.minutes > 2) {
            return `${timeLeft.minutes} minutes`;
        }
        return `${timeLeft.minutes}:${timeLeft.seconds.toString().padStart(2, '0')}`;
    };

    return (
        <>
            <CartTimeoutDialog isOpen={showTimeoutDialog} onClose={handleTimeoutDialogClose} />
            {timeLeft.minutes > 0 || timeLeft.seconds > 0 ? (
                <div className="mt-4 flex justify-end">
                    <p className="text-sm">
                        Time left to complete checkout: <span className="font-medium font-mono">{formatTime()}</span>
                    </p>
                </div>
            ) : null}
        </>
    );
} 