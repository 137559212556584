import React from 'react';
import { clsx } from 'clsx';
import { shopVar } from '@/lib/shopStyles';

interface MainButtonProps {
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
  className?: string;
  url?: string;
  newTab?: boolean;
  style?: React.CSSProperties;
}

export const MainButton: React.FC<MainButtonProps> = ({ disabled, loading, onClick, children, className, url, newTab, style }) => {
  const commonProps = {
    className: clsx(
      'flex items-center justify-center rounded-md px-4 py-2 hover:cursor-pointer hover:opacity-80 group relative',
      {
        'opacity-40': disabled || loading,
        'pointer-events-none': disabled || loading,
      },
      className
    ),
    style: {
      backgroundColor: shopVar('--shop-ctaButtonColor'),
      color: shopVar('--shop-ctaButtonTextColor'),
      ...style
    },
    role: "button",
    tabIndex: disabled || loading ? -1 : 0,
  };

  const content = (
    <>
      <div className={clsx('flex items-center justify-center w-full transition-opacity', { 'opacity-0': loading })}>
        {children}
      </div>
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="animate-spin rounded-full h-6 w-6 border-2 border-t-transparent" style={{
            borderColor: `${shopVar('--shop-ctaButtonTextColor')} transparent ${shopVar('--shop-ctaButtonTextColor')} ${shopVar('--shop-ctaButtonTextColor')}`
          }}/>
        </div>
      )}
    </>
  );

  if (url) {
    return (
      <a href={url} {...commonProps} target={newTab ? '_blank' : '_self'}>
        {content}
      </a>
    );
  }
  return <div {...commonProps} onClick={!disabled && !loading ? onClick : undefined}>{content}</div>;
};

export default MainButton;