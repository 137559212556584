import { createFileRoute, useNavigate } from '@tanstack/react-router'
import { GuestOfferApi } from '@/lib/GuestOfferApi'
import type { PaymentMethod } from '@/lib/TicketApi'
import type { GuestOffer } from '@/lib/GuestOfferApi'
import { Panel } from '@/components/shared/Panel'
import { SelectPaymentMethod } from '@/components/purchase/checkout/SelectPaymentMethod'
import { MainButton } from '@/components/shared/MainButton'
import { Money } from '@/lib/money'
import { useState, useEffect, useMemo } from 'react'
import { useLanguage } from '@/providers/LanguageProvider'
import { RiArrowRightSLine, RiLoader4Line } from '@remixicon/react'
import { calculateFee } from '@/lib/calculateFee'

// Default shop styles for guest offers
const defaultShopStyle = {
  '--shop-textColor': '#000000',
  '--shop-panelBackgroundColor': '#ffffff',
  '--shop-borderColor': '#e5e7eb',
  '--shop-ctaButtonColor': '#000000',
  '--shop-ctaButtonTextColor': '#ffffff',
} as const

function ShopStyleProvider({ children }: { children: React.ReactNode }) {
  useEffect(() => {
    // Set default shop styles
    Object.entries(defaultShopStyle).forEach(([key, value]) => {
      document.documentElement.style.setProperty(key, value)
    })

    // Cleanup function to remove styles when component unmounts
    return () => {
      Object.keys(defaultShopStyle).forEach((key) => {
        document.documentElement.style.removeProperty(key)
      })
    }
  }, [])

  return <>{children}</>
}

function ErrorComponent() {
  return (
    <ShopStyleProvider>
      <div className="bg-gradient-to-b from-white to-[#a7dbc7] sm:p-10 min-h-screen">
        <div className="max-w-[800px] mx-auto bg-white rounded-xl pb-6 shadow-2xl">
          <div className="p-6">
            <h1 className="text-2xl font-bold mb-4">Offer Not Found</h1>
            <p className="text-gray-700 mb-4">
              We couldn't find the offer you're looking for. This could be
              because:
            </p>
            <ul className="list-disc pl-5 mb-6 text-gray-700">
              <li>The offer has expired</li>
              <li>The offer has already been claimed</li>
              <li>The link you used is incorrect</li>
            </ul>
            <p className="text-gray-700">
              If you believe this is an error, please contact support at{' '}
              <a
                href="mailto:ticketing@fastlane.events"
                className="text-blue-600 hover:underline"
              >
                ticketing@fastlane.events
              </a>
            </p>
          </div>
        </div>
      </div>
    </ShopStyleProvider>
  )
}

// Add type for search params
interface SearchParams {
  transactionid?: string;
}

type LoaderData = { offer: GuestOffer } | { order_id: string }

export const Route = createFileRoute('/invite/$inviteId')({
  validateSearch: (search: Record<string, unknown>): SearchParams => ({
    transactionid: search.transactionid as string | undefined,
  }),
  loader: async ({ params: { inviteId } }): Promise<LoaderData> => {
    const api = new GuestOfferApi(import.meta.env.VITE_API_URL)
    return await api.getOffer(inviteId)
  },
  errorComponent: ErrorComponent,
  component: GuestOffer,
})

function GuestOffer() {
  const data = Route.useLoaderData()
  const { inviteId } = Route.useParams()
  const navigate = useNavigate()
  const { currentLanguage: lang } = useLanguage()
  const [paymentMethod, setPaymentMethod] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  // Show processing state if we have a payment_id
  const isProcessingPayment = 'offer' in data && data.offer.payment_id

  // Always declare hooks first, before any conditional logic
  useEffect(() => {
    if ('order_id' in data) {
      navigate({ to: `/order/${data.order_id}` })
    }
  }, [data, navigate])

  // Add polling effect when payment is processing
  useEffect(() => {
    if (!isProcessingPayment) return

    const pollPaymentStatus = async () => {
      try {
        const api = new GuestOfferApi(import.meta.env.VITE_API_URL)
        const result = await api.getOffer(inviteId)
        
        if ('order_id' in result) {
          navigate({ to: `/order/${result.order_id}` })
        }

        if('offer' in result) {
          if (result.offer.payment_id !== null) {
            // reload the page
            window.location.reload()
          }
        }
      } catch (error) {
        console.error('Failed to check payment status:', error)
      }
    }

    // Initial check
    pollPaymentStatus()

    // Set up polling interval
    const interval = setInterval(pollPaymentStatus, 3000)

    // Cleanup
    return () => clearInterval(interval)
  }, [inviteId, navigate, isProcessingPayment])

  const subtotal = useMemo(() => {
    if (!('offer' in data)) return null
    return Money.fromInteger(
      data.offer.price_per_product.amount * data.offer.quantity,
      data.offer.price_per_product.currency,
    )
  }, [data])

  const selectedPaymentMethodFee = useMemo(() => {
    if (!('offer' in data) || !paymentMethod || !subtotal) return null
    const method = data.offer.paymentMethods.find(
      (m: PaymentMethod) => m.id === paymentMethod,
    )
    if (!method) return null
    return calculateFee(method.fee, subtotal, 1)
  }, [data, paymentMethod, subtotal])

  const total = useMemo(() => {
    if (!subtotal) return null
    if (selectedPaymentMethodFee) {
      return subtotal.add(selectedPaymentMethodFee)
    }
    return subtotal
  }, [subtotal, selectedPaymentMethodFee])

  // Handle order response
  if ('order_id' in data) {
    return null
  }

  if (isProcessingPayment) {
    return (
      <ShopStyleProvider>
        <div className="bg-gradient-to-b from-white to-[#a7dbc7] sm:p-10 min-h-screen">
          <div className="max-w-[800px] mx-auto bg-white rounded-xl pb-6 shadow-2xl">
            <div className="p-4 pt-4">
              <Panel>
                <div className="text-center py-8">
                  <div className="flex justify-center mb-6">
                    <RiLoader4Line className="w-12 h-12 text-gray-600 animate-spin" />
                  </div>
                  <h1 className="text-2xl font-bold mb-4">Processing Payment</h1>
                  <p className="text-gray-700 mt-4">
                    Please wait while we process your payment. This may take a few moments.
                    Do not close this window.
                  </p>
                </div>
              </Panel>
            </div>
          </div>
        </div>
      </ShopStyleProvider>
    )
  }

  if (!subtotal || !total) {
    return null
  }

  const { offer } = data

  const handlePayment = async () => {
    if (!paymentMethod) return

    setLoading(true)
    setError(null)
    try {
      const api = new GuestOfferApi(import.meta.env.VITE_API_URL)
      const response = await api.pay(inviteId, paymentMethod)
      if (response.type === 'redirect') {
        window.location.href = response.url
      }
    } catch (error) {
      console.error('Payment failed:', error)
      setError(
        'Payment failed. Please try again or contact support if the problem persists.',
      )
      setLoading(false)
    }
  }

  return (
    <ShopStyleProvider>
      <div className="bg-gradient-to-b from-white to-[#a7dbc7] sm:p-10 min-h-screen">
        <div className="max-w-[800px] mx-auto bg-white rounded-xl pb-6 shadow-2xl">
          <div className="p-4 pt-4">
            <Panel>
              <h1 className="text-2xl font-bold mb-4">Hi {offer.name}</h1>
              {offer.invitation_message && (
                <p className="mb-6 text-gray-700 whitespace-pre-wrap">
                  {offer.invitation_message}
                </p>
              )}
              <div className="mb-4">
                <div className="text-lg font-semibold mb-2">Your Offer</div>
                <div className="flex justify-between items-center py-2 border-b border-gray-200">
                  <div>
                    <span className="font-medium">
                      {offer.quantity}x Ticket
                    </span>
                    <div className="text-sm text-gray-600">
                      {Money.fromInteger(
                        offer.price_per_product.amount,
                        offer.price_per_product.currency,
                      ).display(lang)}{' '}
                      each
                    </div>
                  </div>
                  <div className="font-bold">{subtotal.display(lang)}</div>
                </div>

                {selectedPaymentMethodFee && !selectedPaymentMethodFee.isZero() && (
                  <div className="flex justify-between items-center py-2 border-b border-gray-200">
                    <div className="text-sm text-gray-600">
                      {offer.paymentMethods.find(
                        (m: PaymentMethod) => m.id === paymentMethod,
                      )?.fee.name}
                    </div>
                    <div className="font-bold">
                      {selectedPaymentMethodFee.display(lang)}
                    </div>
                  </div>
                )}
                <div className="flex justify-between items-center py-2 mt-2">
                  <div className="font-bold">Total</div>
                  <div className="font-bold">{total.display(lang)}</div>
                </div>
              </div>

              {error && (
                <div className="mb-4 p-3 bg-red-100 text-red-700 rounded-md">
                  {error}
                </div>
              )}

              <SelectPaymentMethod
                paymentMethods={offer.paymentMethods}
                selectedMethod={paymentMethod}
                onMethodChange={setPaymentMethod}
                className="mb-4"
              />

              <MainButton
                disabled={!paymentMethod || loading}
                onClick={handlePayment}
                className="w-full"
              >
                <div className="text-lg font-bold leading-7">
                  {loading ? 'Processing...' : `Pay ${total.display(lang)}`}
                </div>
                <RiArrowRightSLine className="h-10 w-10 shrink-0 group-hover:translate-x-2 group-hover:scale-110 transition-transform duration-200" />
              </MainButton>
              {offer.email && (
                <div className="text-sm text-gray-600 pt-4 pb-4">
                  After payment your tickets will be sent to your email:{' '}
                  {offer.email}
                </div>
              )}
              {offer.email === null && (
                <div className="text-sm text-gray-600 pt-4 pb-4">
                  After payment you will be redirected to your guestlist spot
                  url. Please save it, since we don't have a way to send it to
                  you.
                </div>
              )}
            </Panel>
          </div>
        </div>
      </div>
    </ShopStyleProvider>
  )
}
