import { MarketplaceApi } from '@/lib/MarketplaceApi';
import { useState } from 'react';
import { shopVar } from '@/lib/shopStyles';

interface WaitlistSignupProps {
    slug: string;
}

export function WaitlistSignup({ slug }: WaitlistSignupProps) {
    const [waitlistEmail, setWaitlistEmail] = useState('');
    const [success, setSuccess] = useState(false);

    const handleJoinWaitlist = async () => {
        const marketplaceApi = new MarketplaceApi(import.meta.env.VITE_API_URL);
        await marketplaceApi.joinWaitlist(slug, waitlistEmail);

        setWaitlistEmail('');
        setSuccess(true);
    }

    return (
        <>
            {success && <p className='text-sm mt-2' style={{ color: shopVar('--shop-textColor') }}>You have been added to the waitlist</p>}
            {!success && <>
                <div className='mt-4 flex gap-4'>
                    <input
                        id="email"
                        name="email"
                        type="email"
                        placeholder="you@example.com"
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                        style={{
                            borderColor: shopVar('--shop-borderColor'),
                            color: shopVar('--shop-textColor'),
                            backgroundColor: shopVar('--shop-panelBackgroundColor'),
                        }}
                        onChange={(e) => {
                            setWaitlistEmail(e.target.value);
                        }}
                        value={waitlistEmail}
                    />
                    <div 
                        className='px-4 py-2 rounded-md grow text-nowrap hover:cursor-pointer hover:opacity-80 flex items-center gap-2' 
                        style={{
                            backgroundColor: shopVar('--shop-ctaButtonColor'),
                            color: shopVar('--shop-ctaButtonTextColor')
                        }}
                        onClick={handleJoinWaitlist}
                    >
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 6V18M18 12H6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        Join the waitlist
                    </div>
                </div>
                <p className='text-sm mt-2' style={{ color: shopVar('--shop-textColor') }}>Get notified when tickets become available</p>
            </>}
        </>
    );
} 