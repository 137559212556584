import { parseISO } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import * as m from "@/paraglide/messages.js"
import { DialogContent, DialogTitle } from '../shared/Dialog';
import { DialogClose } from '@radix-ui/react-dialog';

interface SealedTicketModalProps {
    availableFrom: string;
}

export default function SealedTicketModal({ availableFrom }: SealedTicketModalProps) {
    const releaseDate = availableFrom.split('[')[0];
    return (
        <DialogContent className='bg-[var(--shop-panelBackgroundColor)] text-[var(--shop-textColor)]'>
            <DialogTitle>Ticket Sealed</DialogTitle>
            <div className="mt-4">
                <p className="text-[var(--shop-textColor)]">
                    This ticket is currently sealed. The QR code will be available on:
                    <br/>
                    <span className="font-semibold">
                        {formatInTimeZone(parseISO(releaseDate), 'Europe/Amsterdam', "MMMM d, yyyy 'at' HH:mm")}
                    </span>
                </p>
            </div>
            <DialogClose asChild>
                <button className='bg-[var(--shop-ctaButtonColor)] text-[var(--shop-ctaButtonTextColor)] p-2 rounded-md text-center block w-full hover:opacity-90 transition-all duration-300 hover:shadow-md mt-4'>
                    {m.close()}
                </button>
            </DialogClose>
        </DialogContent>
    );
} 