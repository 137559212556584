import React, { ReactNode } from 'react';
import Warning from '../../../assets/warning.svg';

interface ImportInfoProps {
  children: ReactNode;
  className?: string;
}

interface TitleProps {
  children: ReactNode;
}

interface ItemProps {
  children: string | ReactNode;
}

const ImportInfo: React.FC<ImportInfoProps> & {
  Title: React.FC<TitleProps>;
  Item: React.FC<ItemProps>;
} = ({ children, className }) => {
  return (
      <div className={className}>
        {children}
      </div>
  );
};

const Title: React.FC<TitleProps> = ({ children }) => {
  return (
    <h2 className="text-[var(--shop-textColor)] text-lg leading-7 font-bold mb-2">{children}</h2>
  );
};

const Item: React.FC<ItemProps> = ({ children }) => {
  return (<div className="flex justify-start items-center py-1">
    <img src={Warning} alt="warning" className='w-4 h-4 mr-2' />
    <p className="text-[var(--shop-textColor)] text-sm tracking-tight">
      {typeof children === 'string' 
        ? <span dangerouslySetInnerHTML={{ __html: children }} />
        : children
      }
    </p>
  </div>
  );
};

ImportInfo.Title = Title;
ImportInfo.Item = Item;

export { ImportInfo };