import * as Sentry from "@sentry/react";
import type { RegisteredRouter } from "@tanstack/react-router";

let initialized = false;

export function initializeSentry(router?: RegisteredRouter) {
  if (initialized) {
    return;
  }

  initialized = true;
  
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      ...(router ? [Sentry.tanstackRouterBrowserTracingIntegration(router)] : []),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost"],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    initialScope: {
      tags: {
        'component': 'ticket-shop'
      }
    },
    beforeSend(event) {
      // Check if the error originated from our widget components
      const isWidgetError = event.exception?.values?.some(exception => {
        const stacktrace = exception.stacktrace;
        return stacktrace?.frames?.some(frame => {
          // Check if the error comes from our widget files
          return frame.filename?.includes('/Widget') || 
                 frame.filename?.includes('/TicketshopContainer') ||
                 frame.filename?.includes('shop-widget') ||
                 frame.filename?.includes('shop-embed');
        });
      });

      // Only send the error if it's from our widget
      return isWidgetError ? event : null;
    },
  });
} 