import React, { createContext, useContext, useState, useEffect, ReactNode, useRef } from 'react';
import { Shop, TicketApi } from '@/lib/TicketApi';
import { eventEmitter, ReloadShopConfigPayload } from '@/lib/eventEmitter';
import * as m from "@/paraglide/messages.js"
import { onSetLanguageTag } from '@/paraglide/runtime';
import { useLanguage } from './LanguageProvider';
import { StyleOverwrites } from './StyleOverwrites';
import { useTracking } from '@/tracking/context';

interface ShopContextType {
  shop: Shop;
  isLoading: boolean;
  shopSlug: string;
  baseUrl: string;
  isWidget: boolean;
  error: string | null;
}

const ShopContext = createContext<ShopContextType | undefined>(undefined);

interface ShopProviderProps {
  shop?: Shop;
  shopSlug: string;
  baseUrl: string;
  children: ReactNode;
  isWidget?: boolean;
  usePageBackground?: boolean;
}

export interface ShopParams {
  language: string;
  unlockedProductIds: string[];
}

const PageBackground: React.FC<{ shop: Shop; children: ReactNode }> = ({ shop, children }) => {
  const style = {
    background: `linear-gradient(to bottom, ${shop.style.pageBackgroundFromColor || '#ffffff'}, ${shop.style.pageBackgroundToColor || '#ffffff'})`
  };
  
  return (
    <div className="min-h-screen sm:p-10" style={style}>
      <div className="max-w-[800px] mx-auto rounded-xl pb-6 shadow-2xl" style={{
        backgroundColor: shop.style.panelBackgroundColor || '#ffffff',
      }}>
        {children}
      </div>
    </div>
  );
};

export const ShopProvider: React.FC<ShopProviderProps> = ({ shopSlug, children, shop: initialShop, baseUrl, isWidget, usePageBackground = false }) => {
  const [shop, setShop] = useState<Shop | null>(initialShop ?? null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [cachedShops, setCachedShops] = useState<Record<string, Shop>>({});
  const [loadedTrackers, setLoadedTrackers] = useState<string[]>([]);
  const isInitialMount = useRef(true);

  const trackingProvider = useTracking();
  const { currentLanguage: lang } = useLanguage();

  const [shopParams, setShopParams] = useState<ShopParams>({
    language: lang,
    unlockedProductIds: [],
  });

  const fetchShopConfig = async (params: ShopParams) => {
    const cacheKeyForShop = `${shopSlug}-${params.language}`;
    
    // Only check cache if there are no unlocked products
    if (params.unlockedProductIds.length === 0 && cachedShops[cacheKeyForShop]) {
      setShop(cachedShops[cacheKeyForShop]);
      return;
    }

    try {
      setIsLoading(true);
      const ticketApi = new TicketApi(baseUrl, shopSlug);
      const shop = await ticketApi.loadShop(params.language, params.unlockedProductIds);
      setShop(shop);
      
      // Only cache if there are no unlocked products
      if (params.unlockedProductIds.length === 0) {
        setCachedShops(prev => ({...prev, [cacheKeyForShop]: shop}));
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  // Combined effect for initial load and language changes
  useEffect(() => {
    console.log('Loading shop config:', { shopSlug, language: shopParams.language });
    fetchShopConfig(shopParams);

    // Set up language change listener
    const languageChangeHandler = (newLanguageTag: string) => {
      
      if (isInitialMount.current) {
        isInitialMount.current = false;
        return;
      }

      const newParams = {
        ...shopParams,
        language: newLanguageTag,
      };
      setShopParams(newParams);
      fetchShopConfig(newParams);
    };

    onSetLanguageTag(languageChangeHandler);

    // Cleanup
    return () => {
      // If onSetLanguageTag provides a cleanup method, use it here
    };
  }, [shopSlug]); // Only depend on shopSlug for the initial load

  useEffect(() => {
    if (shop?.conversionTracking?.trackers) {
      for (const tracker of shop.conversionTracking.trackers) {
        if (!loadedTrackers.includes(tracker.id)) {
          trackingProvider.addPixelConfig(tracker);
          setLoadedTrackers(prev => [...prev, tracker.id]);
        }
      }
    }
  }, [shop]);

  useEffect(() => {
    const handleReloadShopConfig = (payload: ReloadShopConfigPayload) => {
      if (payload.shopSlug !== shopSlug) {
        return;
      }

      const hasLanguageChanged = payload.language !== undefined && payload.language !== shopParams.language;
      const hasUnlockedProductsChanged = payload.unlockedProductIds !== undefined && 
        (payload.unlockedProductIds.length !== shopParams.unlockedProductIds.length || 
         payload.unlockedProductIds.some(id => !shopParams.unlockedProductIds.includes(id)));

      if (!hasLanguageChanged && !hasUnlockedProductsChanged) {
        return;
      }

      const newShopParams = {
        language: payload.language ?? shopParams.language,
        unlockedProductIds: payload.unlockedProductIds ?? shopParams.unlockedProductIds,
      };

      console.log('Reloading shop config:', newShopParams);
      setShopParams(newShopParams);
      fetchShopConfig(newShopParams);
    };

    eventEmitter.on('RELOAD_SHOP_CONFIG', handleReloadShopConfig);
    return () => eventEmitter.off('RELOAD_SHOP_CONFIG', handleReloadShopConfig);
  }, [shopParams]); // Add shopParams as dependency

  if (shop === null) {
    return <div>{m.loading()}...</div>;
  }

  const value = {
    shop,
    isLoading,
    error,
    shopSlug,
    baseUrl,
    isWidget: isWidget ?? false,
  };

  return (
    <ShopContext.Provider value={value}>
      <StyleOverwrites shopStyle={shop.style}>
        {usePageBackground ? (
          <PageBackground shop={shop}>
            {children}
          </PageBackground>
        ) : (
          children
        )}
      </StyleOverwrites>
    </ShopContext.Provider>
  );
};

export const useShop = () => {
  const context = useContext(ShopContext);
  if (context === undefined) {
    throw new Error('useShop must be used within a ShopProvider');
  }
  return context;
};

export const useShopWhenAvailable = () => {
  const context = useContext(ShopContext);
  if (context === undefined) {
    return null;
  }
  return context;
};