import { MoneyValues, PaymentMethod } from "./TicketApi";

export interface GuestOffer {
    id: string;
    name: string;
    email: string | null;
    quantity: number;
    price_per_product: MoneyValues;
    invitation_message: string;
    paymentMethods: PaymentMethod[];
    payment_id?: string;
}

export interface GuestOfferResponse {
    offer: GuestOffer;
}

export interface OrderResponse {
    order_id: string;
}

export interface PaymentResponse {
    type: 'redirect';
    url: string;
}

export class GuestOfferApi {
    constructor(
        private readonly baseUrl: string,
    ) {}

    public async getOffer(inviteId: string): Promise<GuestOfferResponse | OrderResponse> {
        const res = await fetch(`${this.baseUrl}/guest-lists/offer/${inviteId}`, {
            cache: "no-cache",
            headers: {
                "Accept": "application/json",
            }
        });

        if (res.status === 404) {
            throw new Error("Offer not found");
        }

        return await res.json();
    }

    public async pay(inviteId: string, paymentMethodId: string): Promise<PaymentResponse> {
        const res = await fetch(`${this.baseUrl}/guest-lists/offer/${inviteId}/pay`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({
                paymentMethodId,
                returnUrl: window.location.href,
            }),
        });

        return await res.json();
    }
} 