import React from 'react';

interface SoldIconProps {
  className?: string;
}

export const SoldIcon: React.FC<SoldIconProps> = ({ className = '' }) => {
  return (
    <svg 
      width="35" 
      height="35" 
      viewBox="0 0 35 35" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
    >
      <g>
        <path d="M17.4999 19.4197C15.9775 19.4197 14.5175 20.0244 13.441 21.1009C12.3645 22.1774 11.7598 23.6374 11.7598 25.1598C11.7598 26.6822 12.3645 28.1422 13.441 29.2187C14.5175 30.2952 15.9775 30.8999 17.4999 30.8999C19.0223 30.8999 20.4823 30.2952 21.5588 29.2187C22.6353 28.1422 23.24 26.6822 23.24 25.1598C23.24 23.6374 22.6353 22.1774 21.5588 21.1009C20.4823 20.0244 19.0223 19.4197 17.4999 19.4197ZM15.0398 25.1598C15.0398 24.5074 15.299 23.8816 15.7604 23.4203C16.2217 22.9589 16.8474 22.6997 17.4999 22.6997C18.1523 22.6997 18.7781 22.9589 19.2394 23.4203C19.7008 23.8816 19.9599 24.5074 19.9599 25.1598C19.9599 25.8122 19.7008 26.438 19.2394 26.8993C18.7781 27.3607 18.1523 27.6199 17.4999 27.6199C16.8474 27.6199 16.2217 27.3607 15.7604 26.8993C15.299 26.438 15.0398 25.8122 15.0398 25.1598Z"/>
        <path d="M26.5625 7.30964L21.3488 0L2.17846 15.3147L1.11572 15.3032V15.3196H0.279297V35H34.72V15.3196H33.1423L30.0033 6.13701L26.5625 7.30964ZM29.6769 15.3196H13.2307L25.4801 11.144L27.9762 10.3453L29.6769 15.3196ZM23.3218 8.41502L10.6771 12.725L20.6912 4.72494L23.3218 8.41502ZM3.55937 28.717V21.5993C4.25175 21.3549 4.88066 20.9587 5.39999 20.4396C5.91932 19.9206 6.31589 19.2919 6.56063 18.5996H28.4387C28.6832 19.2922 29.0797 19.9212 29.5991 20.4406C30.1184 20.9599 30.7474 21.3564 31.44 21.6009V28.7187C30.7474 28.9632 30.1184 29.3597 29.5991 29.879C29.0797 30.3984 28.6832 31.0274 28.4387 31.7199H6.56391C6.31821 31.0273 5.92096 30.3983 5.40116 29.8788C4.88136 29.3593 4.25212 28.9623 3.55937 28.717Z"/>
      </g>
    </svg>
  );
}; 