import { createFileRoute, redirect } from '@tanstack/react-router'

interface TrackingResponse {
  url: string;
}

export const Route = createFileRoute('/t/$tracking')({
  beforeLoad: async ({ params }) => {
    let url = '';
    try {
      // Build query parameters
      const queryParams = new URLSearchParams({
        user_agent: navigator.userAgent,
        referer: document.referrer || '',
      });

      const response = await fetch(
        `${import.meta.env.VITE_API_URL}/tracking-links/${params.tracking}?${queryParams.toString()}`
      );
      
      if (!response.ok) {
        throw new Error('Failed to fetch tracking link');
      }
      const data: TrackingResponse = await response.json();
      url = data.url;
    } catch (error) {
      if (error instanceof Error) {
        console.error('Error fetching tracking link:', error);
      }
      throw redirect({
        to: '/',
        replace: true
      });
    }

    // Add shop_code to the URL
    const redirectUrl = new URL(url, window.location.origin);
    redirectUrl.searchParams.append('shop_code', params.tracking);

    throw redirect({
      to: redirectUrl.pathname + redirectUrl.search,
      replace: true
    });
    
  },
  component: () => null
});


