import React from 'react';
import { DialogContent, DialogTitle } from '../shared/Dialog';
import { Ticket, TicketStatus } from '@/lib/OrderApi';
import { DialogClose } from '@radix-ui/react-dialog';
import AvailableTicket from './AvailableTicket';
import * as m from "@/paraglide/messages.js"

interface ScanOrDownloadTicketModalProps {
    ticket: Ticket,
}

const ScanOrDownloadTicketModal: React.FC<ScanOrDownloadTicketModalProps> = ({ticket}) => {
  return (
      <DialogContent className='bg-[var(--shop-panelBackgroundColor)] text-[var(--shop-textColor)]'>
        <DialogTitle className='text-center mx-auto'>{ticket.productName} <span className='text-xs tracking-tight'> {ticket.numberOfProductType}/{ticket.totalOfProductType}</span></DialogTitle>
        {ticket.ticketStatus === TicketStatus.available && (<AvailableTicket ticket={ticket} />)}
        <DialogClose asChild>
                <button className='bg-[var(--shop-ctaButtonColor)] text-[var(--shop-ctaButtonTextColor)] p-2 rounded-md text-center block w-full hover:opacity-90 transition-all duration-300 hover:shadow-md mt-4'>
                    {m.close()}
                </button>
            </DialogClose>
      </DialogContent>
  );
};

export default ScanOrDownloadTicketModal;
