import React, { useEffect, useState } from 'react';
import { Ticket, TicketStatus } from '@/lib/OrderApi';
import QRCode from 'react-qr-code';
import AddToWallet from '../../../assets/order/add-to-wallet.svg';
import PDFIcon from '../../../assets/order/pdf.svg';
import * as m from "@/paraglide/messages.js"

interface AvailableTicketProps {
  ticket: Ticket;
}

const AvailableTicket: React.FC<AvailableTicketProps> = ({ ticket}) => {
    const isMac = /(iPhone|iPod|iPad)/i.test(navigator.userAgent)
    const [isAppleDevice, setIsAppleDevice] = useState(false);

    useEffect(() => {
    if (isMac) {
      setIsAppleDevice(true);
    }
  }, [isMac])
  
  return (
    <>
     {ticket.ticketStatus === TicketStatus.available && (
            <>
            <div className='text-center mx-auto text-[var(--shop-textColor)]'>
                {ticket.barcode}
            </div>
            <QRCode
                size={256}
                className='mx-auto max-w-[200px] p-4 bg-white my-6'
                level='H'
                style={{ height: "auto"}}
                value={ticket.barcode}
                viewBox={`0 0 256 256`}
            />

            {isAppleDevice && ticket.wallet_download_url && (
                <a href={ticket.wallet_download_url} target="_blank">
                    <img src={AddToWallet} alt="" className='h-16 py-2 mx-auto'/>
                </a>
            )}

            {ticket.pdf_download_url && (
                <a href={ticket.pdf_download_url} className='bg-[var(--shop-ctaButtonColor)] text-[var(--shop-ctaButtonTextColor)] font-bold p-2 rounded-md text-center block w-full hover:opacity-90 transition-all duration-300 hover:shadow-md mt-2' target="_blank">
                    {m.order_available_ticket_modal_download_ticket()} <img src={PDFIcon} alt="" className='ml-2 h-4 inline-block'/>
                </a>
            )}
        </>
    )}
    </>
  );
};

export default AvailableTicket;       